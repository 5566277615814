import apiClient from "../utils/apiClient";

export const cronJobService = {
  async updateCronJob(updateParams) {
    try {
      await apiClient.post(`/cron-job`, updateParams);
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async fetchCronJob(customerId) {
    try {
      const response = await apiClient.get(`/cron-job/${customerId}`);
      return response;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return {};
  },
};
