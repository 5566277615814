// src/axiosConfig.js
import axios from 'axios'

const logoutUser = () => {
  // Clear user token or session data
  localStorage.removeItem('token')
  // Redirect to login page or set state to update UI
  window.location.href = '/sign-in'
}

const axiosInstance = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  }
  // Other configurations
})

axiosInstance.interceptors.request.use(
  function (config) {
    // Assuming you store your token in localStorage or get it from some other source
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Assuming logoutUser() clears local storage and redirects to login
      logoutUser()
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
