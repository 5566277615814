import axios from "axios";

export const geocodeAddress = async (address, market) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        `${address}, ${market}`
      )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    );
    if (response.data.results.length > 0) {
      const { lat, lng } = response.data.results[0].geometry.location;
      return { lat, lng };
    }
  } catch (error) {
    console.error("Error geocoding address:", error);
  }
  return null;
};
