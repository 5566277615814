// src/App.js
import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import StreetRates from './pages/StreetRates'
import ExistingCustomers from './pages/ExistingCustomers'
import Competitors from './pages/Competitors'
import Settings from './pages/Settings'
import SignIn from './pages/SignIn'
import ResetPassword from './pages/ResetPassword'
import Loading from './pages/Loading';
import Portfolio from "./pages/Portfolio";
import { AuthContext } from './context/authContext'
import './styles/index.css'
import { ROLES } from './global/constants'
import CreatePortfolio from './pages/CreatePortfolio'

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext)

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" replace />
  }

  return children
}

const App = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isIntegrator = user?.user?.role?.name === ROLES.INTEGRATOR;
  return (
    <Routes>
      <Route path="/sign-in" exact element={<SignIn />} />
      <Route path="/reset-password" exact element={<ResetPassword />} />
      <Route path="/loading" exact element={<Loading />} />
      <Route
        path="/street-rates"
        element={
          <ProtectedRoute>
            <StreetRates />
          </ProtectedRoute>
        }
      />
      <Route
        path="/existing-customer-rate-increases"
        element={
          <ProtectedRoute>
            <ExistingCustomers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/competitors"
        element={
          <ProtectedRoute>
            <Competitors />
          </ProtectedRoute>
        }
      />
      <Route
        path="/competitors/:id"
        element={
          <ProtectedRoute>
            <Competitors />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/:id"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/portfolio/:id"
        element={
          isIntegrator ? <Portfolio /> : <Navigate to="/sign-in" replace />
        }
      />
      <Route
        path="/portfolio"
        element={
          isIntegrator ? <Portfolio /> : <Navigate to="/sign-in" replace />
        }
      />
      <Route
        path="/portfolio/new"
        element={
          isIntegrator ? <CreatePortfolio /> : <Navigate to="/sign-in" replace />
        }
      />
      <Route path="/" element={<Navigate replace to="/street-rates" />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
