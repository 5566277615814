// src/Navbar.js
import React, { useContext } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import { AuthContext } from '../context/authContext'
import { ROLES } from '../global/constants';

const Navbar = () => {
  const navigate = useNavigate()
  const { logout, auth } = useContext(AuthContext)
  const isIntegrator = auth?.user?.role?.name === ROLES.INTEGRATOR;

  const handleSignOut = () => {
    logout()
    navigate('/sign-in')
  }

  return (
    <div className="flex-3/10 min-h-screen">
      <div className="bg-white w-full h-full px-2 rounded-xl">
        <div className="w-full pt-8">
          <NavLink to="/">
            <img className="w-3/5 mx-auto" src="/assets/images/logo.svg" alt="App Logo" />
          </NavLink>
        </div>
        <ul className="mt-6">
          <li>
            <NavLink to="/street-rates" className="nav-item">
              <span className="material-symbols-rounded">grid_view</span>
              Street Rates
            </NavLink>
          </li>
          <li>
            <NavLink to="/existing-customer-rate-increases" className="nav-item">
              <span className="material-symbols-rounded">supervisor_account</span>
              Existing Customers
            </NavLink>
          </li>
          <li>
            <NavLink to="/competitors" className="nav-item">
              <span className="material-symbols-rounded">leaderboard</span>
              Competitors
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings" className="nav-item">
              <span className="material-symbols-rounded">settings</span>
              Settings
            </NavLink>
          </li>
          {isIntegrator && (
            <li>
              <NavLink to="/portfolio" className="nav-item">
                <span className="material-symbols-rounded">book</span>
                Portfolio
              </NavLink>
            </li>
          )}
          <li>
            <div className="nav-item cursor-pointer" onClick={handleSignOut}>
              <span className="material-symbols-rounded">logout</span>
              Sign Out
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
