import apiClient from "../utils/apiClient";

const facilityService = {
  async fetchListFacilities() {
    try {
      const response = await apiClient.get(`/street_rates`);
      return response?.data?.result || [];
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return [];
  },
  async fetchFacility(id) {
    try {
      const response = await apiClient.get(`/street_rates/${id}`);
      return response?.data?.result || {};
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return null;
  },
  async getPortfolioStrategies(customerId) {
    try {
      const response = await apiClient.get(`/facility_profile/${customerId}/strategies`);
      return response;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return {};
  },
  async getPortfolioValuePricing(customerId) {
    try {
      const response = await apiClient.get(`/facility_profile/${customerId}/value_pricing`);
      return response;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return {};
  },
  async updateFacility(facilityId, updateParams) {
    try {
      await apiClient.put(
        `/facility_profile/facility/${facilityId}`,
        JSON.stringify(updateParams)
      );
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async savePortfolioStrategies(customerId, strategyValue) {
    try {
      await apiClient.post(
        `/facility_profile/${customerId}/save_strategies`,
        { strategyValue }
      );
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async saveFacilityStrategies(facilityId, strategyValue) {
    try {
      await apiClient.post(
        `/facility_profile/facility/${facilityId}/save_strategies`,
        { strategyValue }
      );
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async savePortfolioValuePricing(customerId, valuePricing) {
    try {
      await apiClient.post(
        `/facility_profile/${customerId}/save_value_pricing`,
        { value_pricing: valuePricing }
      );
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async saveFacilityValuePricing(facilityId, valuePricing) {
    try {
      await apiClient.put(
        `/facility_profile/update-facility/${facilityId}`,
        { value_pricing: valuePricing }
      );
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async getEcriSettings(facilityId) {
    try {
      const response = await apiClient.get(`/facility_profile/facility/${facilityId}/ecri`);
      return response;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return {};
  },
  async saveEcriSettings (facilityId, ecriSettings) {
    try {
      await apiClient.put(
        `/facility_profile/facility/${facilityId}/ecri`,
        { ecri_settings: ecriSettings }
      );
      alert("Settings saved successfully");
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async toggleFacilityStatus(id) {
    try {
      const response = await apiClient.put(`/facility_profile/toggle-status/${id}`);
      return response.data.result;
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
};

export default facilityService;
