import Select from "react-select";

const SelectReact = ({
  label,
  options = [],
  onChange,
  value,
  isDisabled = false,
  isLoading = false,
  onInputChange,
  error,
  onLoadMore,
  hasMore,
  inputValue,
  className,
  placeholder = "Select Portfolio",
  defaultValue,
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 46,
      minHeight: 46,
      borderRadius: 8,
      width: "100%",
      border: "1px solid #d1d5db",
    }),
  };

  return (
    <div className={`${className || ""}`}>
      <Select
        styles={customStyles}
        options={options}
        isDisabled={isDisabled}
        onInputChange={onInputChange}
        inputValue={inputValue}
        isLoading={isLoading}
        onChange={onChange}
        value={value || defaultValue}
        onMenuScrollToBottom={onLoadMore}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        // isClearable
        isSearchable={true}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectReact;
