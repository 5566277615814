import React, { useEffect, useState } from 'react'
import { cn } from '../../utils/cn'

const Select = ({ selected, options, onChange, className, label, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [val, setVal] = useState(selected)

  useEffect(() => {
    setVal(selected)
  }, [selected])

  // Toggle dropdown open state
  const toggleDropdown = () => setIsOpen(!isOpen)

  // Close the dropdown if clicked outside of it
  const closeDropdown = (event) => {
    if (!event.target.closest('.dropdown-container')) {
      setIsOpen(false)
    }
  }

  const handleSelected = (option) => {
    setVal(option)
    setIsOpen(false)
    onChange(option)
  }

  React.useEffect(() => {
    window.addEventListener('click', closeDropdown)

    // Cleanup event listener
    return () => window.removeEventListener('click', closeDropdown)
  }, [])

  return (
    <div className="relative inline-block text-left w-auto dropdown-container">
      <div>
        <div
          type="button"
          className={cn("inline-flex justify-between px-4 py-3 text-sm font-medium rounded-md outline-none border rounded-lg border-gray-300", className)}
          onClick={toggleDropdown}
        >
          {label?.[val] || val || placeholder}
          <svg className="w-5 h-5 ml-2 -mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      </div>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="py-1" role="none">
            {options.map((option, index) => 
              <a
                key={index}
                href="#"
                className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                onClick={() => handleSelected(option)}
              >
                {label?.[option] || option}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Select
