import apiClient from "../utils/apiClient";

export const streetRatesService = {
  async fetchStreetRates() {
    try {
      const response = await apiClient.get(`/street_rates/list`);
      return response.data.result
    } catch (error) {
      console.error("Error fetching data: ", error);
      return [];
    }
  },
};
