import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/authContext'
import Modal from '../components/atom/Modal'
import axios from 'axios'

const SignIn = () => {
  const navigate = useNavigate()
  const { login, isAuthenticated } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [forEmail, setForEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false);
  const [passwordReset, setPasswordReset] = useState(0);
  const [resMessage, setResMessage] = useState('');

  if (isAuthenticated) {
    navigate('/street-rates')
  }

  const signIn = (e) => {
    e.preventDefault()
    const form = {
      email,
      password,
    }

    axios
      .post('/api/login', form)
      .then((result) => {
        const { data } = result
        const user = data.result
        const token = user.accessToken
        login(token, user)
        navigate('/loading')
      })
      .catch((error) => {
        setLoginError(true)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(forEmail)
    const form = {
      email: forEmail
    }
    axios
      .post('/api/forgot-password', form)
      .then((result) => {
        const { data } = result
        if (data.statusCode === 200) {
          setPasswordReset(1)
          setResMessage('Please check your email for your password reset instructions.')
        } else {
          setPasswordReset(2)
          setResMessage(data.errors || data.messasge)  
        }
      })
      .catch((error) => {
        setPasswordReset(2)
        setResMessage(error.response.data.errors || error.response.data.messasge)
      })
  }


  return (
    <div className="w-screen h-screen bg-slate-100 flex items-center justify-center">
      <div className="w-full max-w-5xl bg-white rounded-2xl flex flex-wrap">
        <form
          className="flex-1 p-20 bg-red-100 flex flex-col gap-8 rounded-l-2xl"
          onSubmit={signIn}
        >
          <h1 className="text-red-500 text-2xl font-semibold text-center">Sign In</h1>
          <input
            type="email"
            placeholder="Email"
            className="rounded-full w-full border-none outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            className="rounded-full w-full border-none outline-none"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="w-full flex justify-between text-slate-600">
            <div className="flex gap-3">
              <input type="checkbox" id="remember" />
              <label htmlFor="remember">Remember</label>
            </div>
            <a href="#" className="hover:underline" onClick={() => setModalOpen(true)}>
              Forgot Password?
            </a>
          </div>
          {loginError && <div className="text-red-500">Invalid credentials. Please try again.</div>}
          <button type="submit" className="w-full rounded-lg bg-red-500 text-white uppercase">
            Sign In
          </button>
        </form>
        <div className="flex-1 p-20 flex items-center justify-center">
          <img className="w-4/5 mx-auto" src="/assets/images/logo.svg" alt="App Logo" />
        </div>
      </div>
      <Modal isOpen={isModalOpen}>
        <form className="w-96 flex flex-col items-center">
          <h1 className="text-2xl font-bold">Forgot Password?</h1>
          <div className="text-gray-500 my-4">Please enter your email address.</div>
          <input
            required
            type="text"
            className="w-3/4 border-gray-400 text-gray-700 text-sm"
            value={forEmail}
            onChange={(e) => setForEmail(e.target.value)}
          />
          <div className="w-3/4 flex justify-between mt-6">
            <button
              type="button"
              className="border border-radius-lg border-primary uppercase text-primary text-sm"
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="border border-radius-lg border-primary uppercase text-primary text-sm"
              onClick={handleSubmit}
            >
              Send
            </button>
          </div>
          {!!passwordReset && <div className="w-3/4 font-sm text-primary mt-6 text-center">{resMessage}</div>}
        </form>
      </Modal>
    </div>
  )
}

export default SignIn
