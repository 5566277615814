import React, { useEffect, useState } from 'react'
import UnitTypeStatistics from './UnitTypeStatistics'
import { formatDollarWithSymbol, formatPercent } from '../utils/formats'
import { useNavigate } from 'react-router-dom'

const StreetRatesTable = ({ loading, data, handleChange, handleUpdate, onSortChanged, sortColumn, sortDirection }) => {
  const navigate = useNavigate()
  const [rows, setRows] = useState(data)
  const [selectedRow, setSelectedRow] = useState(-1)
  const [rateChanged, setRateChanged] = useState(false)
  const [updatedFacilities, setUpdateFacilities] = useState([])

  useEffect(() => {
    setRows(data)
  }, [data])

  const openUnitMixDetail = async (facility) => {
    setSelectedRow(facility.id)
    setRateChanged(false)
  }

  const handleNewRateChange = (facility, unit) => {
    setRateChanged(true)

    // Update Facility Object with unit type change
    facility.units_statistics = facility.units_statistics.map(ut => {
      if (ut.id === unit.id) {
        return unit
      }
      return ut
    })

    // Update rows data with the selected facility change
    const updatedRows = rows.map(fc => {
      if (fc.facility_id === facility.facility_id) {
        return facility
      }
      return fc
    })

    setRows(updatedRows)
  }

  const handleClose = async (facility) => {
    if (rateChanged) {
      const facilities = [...updatedFacilities]
      if (!facilities.includes(facility.facility_id)) facilities.push(facility.facility_id)
      setUpdateFacilities(facilities)      
      handleChange(facilities.length)
      handleUpdate(facility)
    }
    setRateChanged(false)
    setSelectedRow(-1)
  }

  return (
    <div className="w-full overflow-y-auto">
    <table id="table-facilities" className="w-full">
      <thead>
        <tr>
          <th colSpan={3}></th>
          <th colSpan={4} className="bg-neutral-500 rounded-t-md text-white !py-2">
            New
          </th>
          <th></th>
        </tr>
        <tr className="uppercase text-gray-600 border-t-2 border-neutral-300">
          <th>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => onSortChanged('facility_name', !(sortDirection === 'asc'))}
            >
              Facility
              {sortColumn === 'facility_name' && (
                <span className="material-symbols-rounded">
                  {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                </span>
              )}
            </div>
          </th>
          <th>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => onSortChanged('market', !(sortDirection === 'asc'))}
            >
              Market
              {sortColumn === 'market' && (
                <span className="material-symbols-rounded">
                  {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                </span>
              )}
            </div>
          </th>
          <th>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => onSortChanged('physical_occupancy', !(sortDirection === 'asc'))}
            >
              <span className="text-left">
                Physical
                <br />
                Occupancy
              </span>
              {sortColumn === 'physical_occupancy' && (
                <span className="material-symbols-rounded">
                  {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                </span>
              )}
            </div>
          </th>
          <th className="bg-neutral-500 rounded-bl-md text-left text-white">
            <div className='cursor-pointer' onClick={() => onSortChanged('avr_rate_change_percent', !(sortDirection === 'asc'))}>
              <span className="text-left">
                Average 
                <br />
                Rate
                <br />
                Change %
              </span>
              {sortColumn === 'avr_rate_change_percent' && (
                <span className="material-symbols-rounded">
                  {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                </span>
              )}
            </div>
          </th>
          <th className="bg-neutral-500 text-left text-white">
            <div className='cursor-pointer' onClick={() => onSortChanged('avr_rate_change_amount', !(sortDirection === 'asc'))}>
              <span className="text-left">
                Average
                <br />
                Rate
                <br />
                Change $
              </span>
              {sortColumn === 'avr_rate_change_amount' && (
              <span className="material-symbols-rounded">
                {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
              </span>
            )}
            </div>
          </th>
          <th className="bg-neutral-500 text-left text-white">
            <div className='cursor-pointer' onClick={() => onSortChanged('largest_increase', !(sortDirection === 'asc'))}>
              <span className="text-left">
                Largest
                <br />
                Increase
              </span>
              {sortColumn === 'largest_increase' && (
                <span className="material-symbols-rounded">
                  {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                </span>
              )}
            </div>
          </th>
          <th className="bg-neutral-500 text-left rounded-br-md text-white">
            <div className='cursor-pointer' onClick={() => onSortChanged('largest_decrease', !(sortDirection === 'asc'))}>
              <span className="text-left">
                Largest
                <br />
                Decrease
              </span>
              {sortColumn === 'largest_decrease' && (
                <span className="material-symbols-rounded">
                  {sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                </span>
              )}
            </div>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {rows &&
          rows.map((item, index) => (
            <>
              <tr key={index} className={`${selectedRow === item.id && 'bg-red-100'}`}>
                <td>
                  <div className="bg-neutral-100 p-2 rounded rounded-lg text-wrap w-4/5">
                    {item.facility_name}
                    <br />
                    {item.address}
                  </div>
                </td>
                <td>{item.market}</td>
                <td>{item.physical_occupancy.toFixed(2)}%</td>
                <td className="bg-neutral-100">{formatPercent(item.avr_rate_change_percent)}</td>
                <td className="bg-neutral-100">{formatDollarWithSymbol(item.avr_rate_change_amount)}</td>
                <td className="bg-neutral-100">{formatDollarWithSymbol(item.largest_increase)}</td>
                <td className="bg-neutral-100">{formatDollarWithSymbol(item.largest_decrease)}</td>
                <td className="text-center">
                  {selectedRow === item.id ? (
                    <div className='flex flex-col gap-2'>
                      <button
                        className="rounded-full border border-primary bg-primary text-white w-full flex items-center text-center justify-center relative"
                        onClick={() => handleClose(item)}
                      >
                          {rateChanged ? "Save Changes" : "Cancel"}
                          <span className="material-symbols-outlined absolute right-1.5 top-1.5">
                            cancel
                          </span>
                      </button>
                      <button
                        className="border border-primary text-primary rounded-full flex justify-center items-center w-full text-center"
                        onClick={() => navigate(`/competitors/${item.id}`)}
                      >
                        View Comps
                      </button>
                    </div>
                  ) : (
                    <div className='flex flex-col gap-2'>
                      <button
                        className="border border-black rounded-full flex justify-center items-center w-full text-center"
                        onClick={() => openUnitMixDetail(item)}
                      >
                        {updatedFacilities.includes(item.facility_id)
                          ? 'View Edits'
                          : 'Unit Mix Detail'}
                      </button>
                      <button
                        className="border border-black rounded-full flex justify-center items-center w-full text-center"
                        onClick={() => navigate(`/competitors/${item.id}`)}
                      >
                        View Comps
                      </button>
                    </div>
                  )}
                </td>
              </tr>
              {selectedRow === item.id && (
                <tr key={`${index}-expanded`}>
                  <td colSpan={10} className="!p-0 overflow-visible">
                    <UnitTypeStatistics
                      rows={item.units_statistics}
                      handleChange={(unit) => handleNewRateChange(item, unit)}
                    />
                  </td>
                </tr>
              )}
            </>
          ))}
      </tbody>
    </table>
    </div>
  );
};

export default StreetRatesTable;
