import React, { useState, useEffect } from "react";
import SelectReact from "./SelectReact";
import portfolioService from "../../services/portfolio";

const fetchOptions = async () => {
  const data = await portfolioService.listPortfolios();
  return data.map((item) => ({
    value: item.id,
    label: item.portfolio_name,
  }));
};

const PortfolioSelect = ({ className, value, onChange, defaultValue, placeholder }) => {
  const [options, setOptions] = useState(defaultValue ? [defaultValue] : []);
  const [isLoading, setIsLoading] = useState(false);

  const initialLoad = async () => {
    setIsLoading(true);
    try {
      const data = await fetchOptions();
      setOptions((prevOptions) => [...prevOptions, ...data]);
    } catch (error) {
      console.error("Failed to load options:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialLoad();
  }, []);

  return (
    <SelectReact
      className={className}
      options={options}
      isLoading={isLoading}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default PortfolioSelect;
