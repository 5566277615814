const formatDollar = (num) => {
  if (isNaN(num) || !num) return ''
  num = +num;
  return num.toFixed(2);
}

const formatDollarWithSymbol = (num) => {
  if (isNaN(num)) return '$0.00'
  num = + num;
  if (num > 0) return `+$${formatDollar(num)}`
  else if (num < 0) {
    return `-$${formatDollar(num).toString().slice(1)}`
  }
  return `$0`
}

const formatPercent = (num) => {
  if (isNaN(num)) return '0%'
  num = parseFloat(num).toFixed(2)
  if (num > 0) return `+${num}%`
  else if (num < 0) return `${num}%`
  return `0%`
}

export {
  formatDollar,
  formatDollarWithSymbol,
  formatPercent
}