import apiClient from "../utils/apiClient";
import { saveAs } from "file-saver";

const unitService = {
  async downloadSampleXLSX(facility_id) {
    try {
      const response = await apiClient.get("/unit/sample-xlsx", {
        params: { type: "excelFormat", facility_id },
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, `sample.xlsx`);
    } catch (error) {
      alert(error.response.data.errors.toString() || error.toString());
      console.error("Error downloading sample XLSX file", error);
    }
    return {};
  },
  async uploadXLSX(file, facility_id) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("facility_id", facility_id);
      const response = await apiClient.post("/unit/upload-xlsx", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("XLSX file uploaded successfully");
      return response.data.result;
    } catch (error) {
      alert(error.response.data.errors.toString() || error.toString());
      console.error("Error uploading XLSX file", error);
    }
    return {};
  },
  async exportXLSX(facility_id) {
    try {
      const response = await apiClient.get("/unit/export-xlsx", {
        params: { type: "excelFormat", facility_id },
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "units.xlsx");
    } catch (error) {
      alert(error.response.data.errors.toString() || error.toString());
      console.error("Error exporting XLSX file", error);
    }
    return {};
  },
};

export default unitService;
