import apiClient from "../utils/apiClient";
import { USER_ROLE_ID } from "../global/constants";

const customerService = {
  async getUserByPortfolioId(portfolioId) {
    try {
      const response = await apiClient.get(
        `/customers/users?portfolio_id=${portfolioId}&role_id=${USER_ROLE_ID}`
      );
      return response.data.result;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return [];
  },
  async createUser(payload) {
    return apiClient.post(`/customers/users`, payload);
  },
  async deleteUser(id) {
    try {
      await apiClient.delete(`/customers/users/${id}`);
      alert("User deleted successfully");
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
};

export default customerService;
